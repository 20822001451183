import Cookies from 'js-cookie';
import { Buffer } from 'buffer';
import { checkRefreshTokenAB } from '@/resources/refreshTokenResource';

function isContaAzulLogin(email) {
  return email.toLowerCase().endsWith('@contaazul.com');
}

function setRefreshTokenCookie(value) {
  Cookies.set('ca-refresh-token-ab', value, {
    domain: process.env.VUE_APP_COOKIE_DOMAIN,
    secure: window.location.protocol === 'https:',
    expires: 7,
  });
}

export async function fetchAndSetRefreshTokenAB(email) {
  if (isContaAzulLogin(email)) {
    setRefreshTokenCookie('enabled');
  } else {
    const hash = Buffer.from(email).toString('base64');
    const { data: refreshTokenAB } = await checkRefreshTokenAB(hash);

    setRefreshTokenCookie(refreshTokenAB ? 'enabled' : '');
  }
}
