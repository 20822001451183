import jwtDecode from 'jwt-decode';
import Cookies from 'js-cookie';
import { to } from 'await-to-js';
import { getError } from './errorsService';
import { amplifyFetchUserSession, amplifyGetCurrentUser, getStorageInstance } from './amplifyService.js';
import { getProductLocationByUser } from './locationService';
import { bootstrapJWT } from './bootstrapJWTResource';
import { fetchAndSetRefreshTokenAB } from './refreshTokenService.js';

export async function bootstrapAuthToken(app) {
  let auth;

  const [, userData] = await to(amplifyFetchUserSession());
  const [, currentUser] = await to(amplifyGetCurrentUser());

  if (userData?.tokens?.accessToken?.payload) {
    const { client_id: clientId, username, exp, device_key: deviceKey } = userData.tokens.accessToken.payload;

    const key = `CognitoIdentityServiceProvider.${clientId}.${username}.refreshToken`;

    auth = {
      access_token: userData.tokens.accessToken?.toString(),
      id_token: userData.tokens.idToken?.toString(),
      refresh_token: await getStorageInstance().getItem(key),
      expiration: exp,
      expiresIn: exp - parseInt(Date.now() / 1000),
      deviceKey,
    };
  }

  if (auth == null) {
    console.log('erro: objeto auth inválido');
    console.log(userData);

    const error = { error: JSON.stringify({ ...getError('MissingTokenData') }) };
    return [error, null];
  }

  const { userId } = jwtDecode(auth.id_token);

  if (!userId) {
    console.log('erro: usuario CAPRO indisponível');
    const error = { error: JSON.stringify({ ...getError('MissingTokenDataAfterLogin') }) };
    return [error, null];
  }

  if (auth.expiration) {
    Cookies.set('ca-access-token-expiry', (auth.expiration * 1000).toString(), {
      domain: process.env.VUE_APP_COOKIE_DOMAIN,
      secure: window.location.protocol === 'https:',
      expires: auth.expiresIn / (60 * 60 * 24),
    });
  }

  const jwt = {
    token: auth.access_token,
    refreshToken: auth.refresh_token,
    deviceKey: auth.deviceKey,
  };

  const email = currentUser.username;

  const [[jwtErr]] = await Promise.all([to(bootstrapJWT(jwt)), fetchAndSetRefreshTokenAB(email)]);

  if (jwtErr) {
    const error = { message: getError(jwtErr.code, null), step: 'bootstrapJWT' };
    console.log(jwtErr);
    return [error, null];
  }

  const location = await getProductLocationByUser({ app, email });

  return { location, jwt: true };
}
